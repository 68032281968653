var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('layout-main',[_c('div',{staticClass:"grid grid-cols-12 w-full"},[_c('div',{staticClass:"col-span-6 flex flex-col items-center border-x col-start-4 h-[83vh]"},[(_vm.isLoading)?_c('div',{staticClass:"w-full bg-white flex justify-center h-[83vh] items-center"},[_c('span',{staticClass:"text-base"},[_vm._v("Mohon tunggu...")])]):_vm._e(),(_vm.consultation === null && !_vm.isLoading)?_c('div',{staticClass:"w-[60%] bg-white text-center h-[83vh] flex justify-center items-center flex-col"},[_c('img',{staticClass:"w-[90px]",attrs:{"src":require("@/assets/speak.png"),"alt":""}}),_c('span',{staticClass:"text-xl text-black mt-4 font-semibold"},[_vm._v("Konsultasikan kebutuhan kamu dengan admin untuk mendapatkan rekomendasi terbaik")]),_c('span',{staticClass:"text-sm mt-4"},[_vm._v("Anda memerlukan 1 koin untuk 1x sesi konsultasi selama 24 jam. Pastikan anda saldo koin anda cukup.")]),_c('a-button',{staticClass:"mt-6",attrs:{"loading":_vm.isLoadingCreate,"disabled":_vm.isLoadingCreate,"type":"primary"},on:{"click":_vm.createConsultation}},[_vm._v("Konsultasi sekarang")])],1):_vm._e(),(_vm.consultation !== null && !_vm.isLoading)?_c('div',{staticClass:"w-full text-center h-[84vh] flex justify-start items-center flex-col"},[_c('div',{staticClass:"w-full top-0 h-[60px] flex justify-start px-5 border bg-white"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('span',{staticClass:"font-medium text-black text-base"},[_vm._v(_vm._s(_vm.consultation.admin.fullname))]),_c('i',{staticClass:"ri-checkbox-circle-fill ml-1 text-green-500 text-base"})])]),_c('div',{staticClass:"flex w-full overflow-y-auto px-2 py-2 h-[61vh] flex-col",attrs:{"id":"container-chat"}},[_c('a-alert',{staticClass:"mb-2",attrs:{"description":`Sesi konsultasi akan kadaluarsa pada ${_vm.$helpers.passDate(
              _vm.consultation.createDate,
              1,
              'days'
            )}`,"banner":"","type":"info"}}),_vm._l((_vm.chats),function(item){return _c('div',{class:[
              'flex items-center flex-row my-1',
              item.creators?.userId === _vm.$store.state.auth.user.userId
                ? 'justify-end'
                : 'justify-start',
            ]},[_c('div',{class:[
                'p-3 flex flex-col rounded max-w-[50%] flex w-max',
                item.creators?.userId === _vm.$store.state.auth.user.userId
                  ? 'bg-primary items-end text-end'
                  : 'bg-white border items-start text-start',
              ]},[_c('span',{class:[
                  'text-base',
                  item.creators?.userId === _vm.$store.state.auth.user.userId
                    ? 'text-white'
                    : 'text-black',
                ]},[_vm._v(_vm._s(item.content))]),_c('span',{class:[
                  'text-xs mt-1',
                  item.creators?.userId === _vm.$store.state.auth.user.userId
                    ? 'text-white'
                    : 'text-black',
                ]},[_vm._v(_vm._s(_vm.$helpers.fullDate(item.createDate)))])])])})],2),_c('div',{staticClass:"w-full bottom-0 col-span-6 border flex flex-row items-center justify-center py-3 col-start-4 px-4 bg-white"},[_c('a-textarea',{attrs:{"placeholder":"Masukan pesan...","rows":2},model:{value:(_vm.formChat.content),callback:function ($$v) {_vm.$set(_vm.formChat, "content", $$v)},expression:"formChat.content"}}),_c('a-button',{staticClass:"ml-2",attrs:{"type":"primary","shape":"circle"},on:{"click":_vm.onSendMessage}},[_c('i',{staticClass:"ri-send-plane-line"})])],1)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }